.BackDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;
  }
}

@media (min-width: 1040px) {
  .BackDrop {
    display: none;
  }
}
