* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.NavBar {
  list-style: none;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 40px;
  text-transform: uppercase;
  box-shadow: 0 -5px 5px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0px;
}
.Logo {
  margin: 0px;
  width: 110px;
}
.Logo img {
  width: 50px;
  margin-top: 4px;
  margin-left: 8px;
  opacity: 0.8;
}
.fa-instagram-square,
.fa-linkedin-square,
.fa-envelope-square {
  color: rgb(0, 0, 0);
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.fa-instagram-square:hover,
.fa-linkedin-square:hover,
.fa-envelope-square:hover {
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.Spacer {
  flex: 1;
}

.FooterList {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: flex-end;
  width: 110px;
  margin-top: 2px;
  font-size: 28px;
  li {
    padding: 3px 0px;
  }
}
ul {
  text-align: center;
  list-style: none;
  margin: 0px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 1px;
  justify-content: space-evenly;
  li {
    padding: 0 12px;
  }
  button {
    font-size: 12px;
    background: none;
    outline: none;
    border: none;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    transition: opacity 0.2s ease;
  }
  button:hover {
    opacity: 0.5;
    cursor: pointer;
    text-decoration: underline;
  }
  button:active {
    transform: translateY(1px);
  }
}

@media screen and (max-width: 1039px) {
  .List {
    display: none;
  }
  .Navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1040px) {
  .hamburgerButton {
    display: none;
  }
}
