@import "_typography";
@import "_variables";

* {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  height: 100%;
}
