.Footer {
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  height: 40px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  background: white;
  width: 100%;
}
