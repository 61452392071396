.PopUp {
  display: flex;
  z-index: 200;
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  img {
    max-height: calc(100% - 25px);
    max-width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.848);
    margin-bottom: 25px;
  }
}
.PopUp:hover {
  cursor: pointer;
}
.info {
  display: flex;
  height: 40px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  background: white;
  width: 100%;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.797);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.548);
}
