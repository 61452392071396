.SideDrawer {
  height: 100%;
  background: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 75%;
  max-width: 300px;
  z-index: 80;
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;
  padding-top: 40px;
  padding-bottom: 25px;

  &.open {
    transform: translateX(0%);
  }

  ul {
    height: 100%;
    text-decoration: none;
    flex-direction: column;
    align-items: flex-end;
  }
  li {
    margin-right: 20px;
  }
  button {
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@media (min-width: 1040px) {
  .SideDrawer {
    display: none;
  }
}
