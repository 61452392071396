@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,700;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://kit.fontawesome.com/4b0ed5505f.js);
.allContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; }

.SideBarleft {
  height: 100%;
  position: fixed;
  left: 0;
  width: 40px; }

.SideBarright {
  height: 100%;
  position: fixed;
  right: 0;
  width: 40px; }

.Content {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.Title {
  display: none; }

.Image {
  padding: 4px 6px; }

.Image:hover {
  cursor: pointer; }

.Landscape {
  width: 98vw;
  max-width: 800px; }

.Portrait {
  width: 49vw;
  max-width: 400px;
  max-height: 600px; }

@media screen and (min-width: 1000px) {
  .Content {
    width: 85%; } }

@media screen and (min-width: 1370px) {
  .Content {
    width: 70%; } }

@media screen and (min-width: 1700px) {
  .Content {
    width: 50%; } }

@media screen and (min-width: 2300px) {
  .Content {
    width: 40%; } }

@media screen and (max-width: 1000px) {
  .Content {
    width: 100%; } }

.DrawerToggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 26px;
  width: 30px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 5px;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }

.DrawerToggleButton:focus {
  outline: none; }

.DrawerToggleButton:hover {
  cursor: pointer;
  opacity: 0.5; }

.button_line {
  width: 30px;
  height: 4px;
  background: black; }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.NavBar {
  list-style: none;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  height: 40px;
  text-transform: uppercase;
  box-shadow: 0 -5px 5px 5px rgba(0, 0, 0, 0.25);
  z-index: 100; }

.Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0px; }

.Logo {
  margin: 0px;
  width: 110px; }

.Logo img {
  width: 50px;
  margin-top: 4px;
  margin-left: 8px;
  opacity: 0.8; }

.fa-instagram-square,
.fa-linkedin-square,
.fa-envelope-square {
  color: black;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }

.fa-instagram-square:hover,
.fa-linkedin-square:hover,
.fa-envelope-square:hover {
  color: black;
  opacity: 0.5; }

.Spacer {
  flex: 1 1; }

.FooterList {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: flex-end;
  width: 110px;
  margin-top: 2px;
  font-size: 28px; }
  .FooterList li {
    padding: 3px 0px; }

ul {
  text-align: center;
  list-style: none;
  margin: 0px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 1px;
  justify-content: space-evenly; }
  ul li {
    padding: 0 12px; }
  ul button {
    font-size: 12px;
    background: none;
    outline: none;
    border: none;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
  ul button:hover {
    opacity: 0.5;
    cursor: pointer;
    text-decoration: underline; }
  ul button:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }

@media screen and (max-width: 1039px) {
  .List {
    display: none; }
  .Navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start; } }

@media screen and (min-width: 1040px) {
  .hamburgerButton {
    display: none; } }

.SideDrawer {
  height: 100%;
  background: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 75%;
  max-width: 300px;
  z-index: 80;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-top: 40px;
  padding-bottom: 25px; }
  .SideDrawer.open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  .SideDrawer ul {
    height: 100%;
    text-decoration: none;
    flex-direction: column;
    align-items: flex-end; }
  .SideDrawer li {
    margin-right: 20px; }
  .SideDrawer button {
    font-size: 16px;
    letter-spacing: 1px; }

@media (min-width: 1040px) {
  .SideDrawer {
    display: none; } }

.BackDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .BackDrop:hover {
    cursor: pointer; }
  .BackDrop.open {
    opacity: 1;
    pointer-events: initial; }

@media (min-width: 1040px) {
  .BackDrop {
    display: none; } }

.Footer {
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
  display: flex;
  height: 40px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  width: 100%; }

.PopUp {
  display: flex;
  z-index: 200;
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); }
  .PopUp img {
    max-height: calc(100% - 25px);
    max-width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.848);
    margin-bottom: 25px; }

.PopUp:hover {
  cursor: pointer; }

.info {
  display: flex;
  height: 40px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  width: 100%;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.797);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.548); }

.Container {
  color: black;
  height: 100%;
  width: 100%; }

* {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  height: 100%; }

